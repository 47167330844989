export const BASE_URL = import.meta.env.VITE_BASE_URL;
export const FILE_UPLOAD_URL = import.meta.env.VITE_UPLOAD_URL;
export const ROUTER_BASE_URL = import.meta.env.VITE_ROUTER_BASE_URL;
export const BASE_VIEW_URL = import.meta.env.VITE_BASE_VIEW_URL;
export const OFFICIAL_APP_ID = import.meta.env
  .VITE_DOCTOR_OFFICIAL_ACCOUNT_APP_ID;
export const WX_MINI_APP_ID = import.meta.env.VITE_APP_PATIENT_WX_MINI_APP_ID;

export const PLATFORM_TYPE = {
  MP: 'applet' /** 小程序 */,
  WEB: 'web' /** 网页 */,
  APP: 'app' /** 原生APP */,
  H5: 'h5',
};
export const BIZ_TYPE = {
  COE_DOCTOR: 'coeDoctor',
};

export const VERSION = '2.6.3';
